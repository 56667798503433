import React, { useEffect } from "react";
import { graphql,Link } from "gatsby"
import Layout from "../components/layout"
import "../styles/global.css"
import Card from "../components/NewCard"
// import CarrousselPro from "../components/CarrousselPro"
import { SEO } from "../components/seo"
import SocialLinks from "../components/socialPro"

import bbLogo from "../images/manualdomaker.svg"

export default function IaRenderer({ data, pageContext }) {

  const articles = data.allStrapiArticle.nodes
  const { currentPage, numPagesIa } = pageContext
  const isFirst = currentPage === 1 
  const prevPage = currentPage === 2 ? "/ia" : "/ia/" + (currentPage - 1).toString()
  const nextPage = "/ia/" + (currentPage + 1).toString()

  useEffect(() => {
    // Verifica se estamos no ambiente de navegador
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      // Cria o elemento de script
      const script = document.createElement('script');

      // Define o conteúdo do script
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-SE4NY559V3');
      `;

      // Insere o script no início do body
      document.body.insertBefore(script, document.body.firstChild);

      // Limpeza ao desmontar o componente
      return () => {
        document.body.removeChild(script);
      };
    }

  }, []);  // O array vazio significa que este useEffect só é executado uma vez após o primeiro render

  
    return (
    <>
      <Layout>
        <section className="backdrop-blur-sm centered-content">

        <div className="bg-no-repeat bg-center bg-fixed bg-blend-multiply rounded-2xl ring-2 ring-orange-500 bg-black bg-opacity-80 overflow-hidden" > 

        <div className="h-60 bg-gradient-to-b from-black to-transparent to-50%   w-full">
         
        <div className="relative h-40 top-12 bg-black opacity-70 rounded-xl w-80 ring-opacity-30 ring-1 ring-neonGreen"  style={{ backgroundImage: `url(${bbLogo})`, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}>
                
        <p className="absolute left-2 -top-5 font-sans font-medium z-20 text-[44px] opacity-90">Manual</p>
                            <p className="absolute left-36 top-12 font-sans font-medium z-20 text-[24px]">do</p>
                            <p className="absolute right-2 top-20 font-sans font-medium z-20 text-[44px] opacity-90">Maker</p>
                            <p className="absolute text-orange-500 left-1/2 translate-x-1/2 top-7 font-sans font-medium z-20 text-[96px] opacity-90 mt-7 ml-16 ">.</p>
                            <p className="absolute text-orange-500 left-2/3 ml-12 mt-6 top-28 font-sans font-medium z-20 text-[28px] opacity-90">com</p>
          
                {/* <CarrousselPro/>  */}
            </div> 
                          </div> 

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 cursor-pointer">
        {articles.map((article) => (
          
          <Card
            key={article.id}
            linkTo={article.slug}
            image={article.thumbnail.localFile.childImageSharp.gatsbyImageData}
            title={article.title}
            text={article.excerpt}
            categoryName={article.category.slug} 
          />
          
         
          
        ))}
        </div>

        <div className="flex justify-between bg-gradient-to-b from-transparent to-black  h-16 ">
                            {!isFirst && (
                                <Link to={prevPage} rel="prev">
                                    ← Página anterior
                                </Link>
                            )}
                            {currentPage < numPagesIa && (
                                <Link to={nextPage} rel="next">
                                     Próxima página →
                                </Link>
                            )}
                            </div>
        
      </div>{/*  div que comporta toda a página*/}
      <div className="w-[224px] absolute top-0 -right-120 hidden lg:block"><SocialLinks /></div>
      
        </section>
        
      
      </Layout>
    </>
    )
}

export const myQuery = graphql`
query ArduinoArticles($skip: Int!, $limit: Int!) {
  allStrapiArticle(
    filter: {category: {slug: {eq: "ia"}}}
    limit: $limit
    skip: $skip
    sort: {publishedAt: DESC}
  ) {
    nodes {
      id
      title
      slug
      excerpt
      thumbnail {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: WEBP
              webpOptions: { quality: 85 }
              quality: 85
            )
          }
        }
      }
      body {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      slug
      title
      category {
        slug
      }
    }
  }
}
  `

  export const Head = ({data}) => (
    <SEO title="Categoria: IA - Manual do Maker" description="Categoria dedicada a machine learning e visão computacional" pathname={"ia"} pageType={"CollectionPage"} thumbnail={"/manualdomaker-ia.png"}/> 
  )